function getXmlData(data) {
  if (data.includes('Ocorreu um erro')) {
    throw new Error('Erro ao passar Xml para Json');
  }
  const start = data.search('<arqJso>');
  const end = data.search('</arqJso>');
  const slicedData = data.slice(start + 8, end);

  return JSON.parse(slicedData).Dados;
}

function getXmlResponse(data) {
  if (data.includes('Ocorreu um erro')) {
    throw new Error('Erro ao passar Xml para Json');
  }

  try {
    const start = data.search('<tipRet>');
    const end = data.search('</tipRet>');
    return data.slice(start + 8, end);
  } catch (error) {
    console.log(error);
    throw error;
  }
}
function getXmlMessage(data) {
  if (data.includes('Ocorreu um erro')) {
    throw new Error('Erro ao passar Xml para Json');
  }
  try {
    const start = data.search('<msgRet>');
    const end = data.search('</msgRet>');
    const message = data.slice(start + 8, end);
    if (data.includes('<tipRet>2</tipRet>')) {
      throw new Error(message);
    }
    return message;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export { getXmlData, getXmlResponse, getXmlMessage };
