import React from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';

import App from './App.js';
import Statup from './Statup';

export default function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/statup" element={<Statup />} />
      </Routes>
    </Router>
  );
}
